import { navigate } from 'gatsby'
import React, { useEffect } from 'react'

const Home = () => {
  useEffect(() => {
    navigate('/app')
  }, [])

  return <></>
}

export default Home
